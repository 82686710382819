<template>
  <div class="vlite-content-container">
    <div class="content-card-div">
      <div v-for="content in passContents" :key="content.contentid" class="content-image">
         <content-card
            :content="content"
            :orientation="orientation"
            :displayClose="displayClose"
            :showInfo="true"
            :fromPage="fromPage"
          />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/main";

export default {
  name: "list-contents",
  props: {
    passContents: {
      type: Array,
    },
    contentType: {
      type: String,
    },
    orientation: {
      type: String,
    },
    categoryTitle: {
      type: String,
    }
  },
  data() {
    return {
      detailContent: null,
      displayClose: false,
      fromPage: "",
      createDetailCard:false
    };
  },
  methods: {
    ...mapActions(["actGetContent"]),
    // getContentContinueWatching() {
    //   let param = {};
    //   param.continuewatching = true;
    //   let responseData = [];
    //   let duration;
    //   let watched;
    //   let progress;
    //   this.fetchAllWatchlistContentFromFireBase(param)
    //     .then((response) => {
    //       if (response && response.length) {
    //         response.forEach((element) => {
    //           duration = element.duration;
    //           watched = element.watchedduration;
    //           progress = (watched / duration) * 100;
    //           if (progress > 1) {
    //             element.progress = progress;
    //           }
    //           responseData.push(element);
    //         });
    //       }

    //       this.fetchAllWatchlistSeriesFromFireBase(param).then((result) => {
    //         if (result.length > 0) {
    //           result.forEach((elementSeries) => {
    //             duration = elementSeries.duration;
    //             watched = elementSeries.watchedduration;
    //             progress = (watched / duration) * 100;
    //             if (progress > 1) {
    //               elementSeries.progress = progress;
    //             }
    //             responseData.push(elementSeries);
    //           });
    //         }

    //         this.passContents = responseData;
    //         let largest;
    //         if (
    //           this.passContents.length > 0 &&
    //           this.passContents[0].watchlisttimestamp
    //         ) {
    //           largest = this.passContents[0].watchlisttimestamp;
    //         } else {
    //           largest = 0;
    //         }

    //         this.passContents.forEach((element, index) => {
    //           if (
    //             element.watchlisttimestamp &&
    //             largest < element.watchlisttimestamp
    //           ) {
    //             largest = element.watchlisttimestamp;
    //             this.passContents.splice(index, 1);
    //             this.passContents.unshift(element);
    //           }
    //         });
    //       });
    //     })
    //     .catch((error) => {
    //       console.log("error");
    //     });
    // },
    switchtoRelated(data){
      if (data && data.content && this.detailContent) {
          this.detailContent = data.content;
        }
    }
  },
  created() {
    // if (this.contentType === "continuewatching") {
    //   this.displayClose = true;
    //   this.fromPage = "continuewatching";
    //   this.orientation = "LANDSCAPE";
    //   this.getContentContinueWatching();
    // } 
    if(this.$route.path==='/watchList') {
      this.displayClose=true;
      this.fromPage="watchlist";
      this.orientation="LANDSCAPE";
    }
    else{ 
      this.displayClose = false;
    }

    // EventBus.$on("catogoryChange", this.closeContentDetail);
    // EventBus.$on("deleteContent", this.getContentContinueWatching);
    EventBus.$on("relatedContentClicked",this.switchtoRelated);
  },
  beforeDestroy(){
      // EventBus.$off("deleteContent", this.getContentContinueWatching);
      EventBus.$off("relatedContentClicked",this.switchtoRelated);
  },
  components: {
    "content-card": () =>
      import(
        /* webpackChunkName: "contentCard" */ "@/components/Shared/templates/contentCard.vue"
      ),
  },
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_mediaQueries.scss";

.vlt-details {
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
}

.vlite-content-container {
  display: block;
  margin: 25px 0;
  .vlite-content-row {
    margin: 60px 0;
    .vlite-content-width {
      width: 90%;
      margin: 0 auto;
      display: flex;
      .vlite-content-item-3 {
        width: 33.3%;
        padding: 0 15px;
      }
      .vlite-content-item-2 {
        width: 50%;
        padding: 0 15px;
      }
      .vlite-content-item-1 {
        width: 100%;
        padding: 0 15px;
      }
      .down-arrow {
        position: relative;
        top: -6px;
        height: 15px;
        span {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          width: 0;
          height: 0;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-top: 12px solid $theme-primary;
        }
      }
    }
  }
  .vlite-content-row:first-child {
    margin-top: 20px;
  }
  .vlite-content-row:last-child {
    margin-bottom: 20px;
  }
}

.content-card-div {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap:45px 20px;
}
// .content-image {
//   width: 19vw;
// }
@include breakpoint(max992) {
  // .content-image {
  //   width: 26vw;
  // }
 .content-card-div {
     grid-template-columns: repeat(3, minmax(0, 1fr));
     grid-gap:15px;
  }
}
@include breakpoint(max768) {
  // .content-image {
  //   width: 38vw;
  // }
  .content-card-div {
     grid-template-columns: repeat(2, minmax(0, 1fr));
     grid-gap:10px;
  }
}
</style>